import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  Chip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar
} from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { KeywordType, SnackbarType, NewsType } from '../types/types';
import { ReactComponent as SuccessIcon } from '../assets/icons/CheckSuccessIcon.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/SearchIcon.svg';
import { ReactComponent as DownArrowFilled } from '../assets/icons/DownArrowFilled.svg'
import { ReactComponent as CheckedCheckbox } from '../assets/icons/CheckedCheckBox.svg'
import NotificationSnackbar from './shared/NotificationSnackbar';
import SortOrderButton from './shared/SortOrderButton';
import KeywordChipList from './keywords/KeywordChips';
import NewsItemsModal from './NewsItemsModal';
import { group } from 'console';

const KeywordsPanel: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [groupKeywords, setGroupKeywords] = useState<KeywordType[]>([]);
  const [newlyAddedKeywordIds, setNewlyAddedKeywordIds] = useState<Set<number>>(new Set()); // 最近追加されたキーワードのIDを管理

  const [newsCounts, setNewsCounts] = useState<Record<number, number>>({});
  const [loading, setLoading] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [addingKeyword, setAddingKeyword] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });

  const [selectMode, setSelectMode] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState<number[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // キーワードフィルタリング関連
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortOrder, setSortOrder] = useState<'added' | 'title' | 'count'>('added');
  const [filteredKeywords, setFilteredKeywords] = useState(groupKeywords);

  const [selectedNews, setSelectedNews] = useState<NewsType[]>([]);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');


  const toggleSelectMode = () => {
    setSelectMode(!selectMode)
    // 選択している状態は引き継がない
    unselectKeywords()
  };

  // キーワードの選択を全て解除する
  const unselectKeywords = () => {
    setSelectedKeywords([])
  }

  // キーワードの並び順を変更する
  const toggleSortOrder = () => {
    const nextSortOrder =
      sortOrder === 'added' ? 'count' : sortOrder === 'count' ? 'title' : 'added';
    setSortOrder(nextSortOrder);

    // 並び替えを適用
    sortKeywords(filteredKeywords, nextSortOrder);
  };

  // キーワードを並び替える
  const sortKeywords = (keywords: KeywordType[], order: string) => {
    const sortedKeywords = [...keywords];
    if (order === 'count') {
      sortedKeywords.sort((a, b) => (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0));
    } else if (order === 'title') {
      sortedKeywords.sort((a, b) => a.keyword.localeCompare(b.keyword));
    } else {
      sortedKeywords.sort((a, b) => a.id - b.id); // 追加順
    }
    setFilteredKeywords(sortedKeywords);
  };

  // 検索窓の入力変更をハンドリング
  const handleSearchChange = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
    // 入力値に基づいてフィルタリング
    const filtered = groupKeywords.filter((keyword) =>
      keyword.keyword.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    // 現在の並び順を適用して更新
    sortKeywords(filtered, sortOrder);
  };

  // キーワード一覧か並び順に変化があった場合、フィルタを再適用
  useEffect(() => {
    sortKeywords(groupKeywords, sortOrder)
    handleSearchChange(searchKeyword)
  }, [groupKeywords, sortOrder])

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        } catch (error) {
          console.error('Error fetching access token:', error);
          setSnackbarState({ open: true, message: 'トークンの取得に失敗しました', color: 'red', success: false });
        }
      }
    };
    fetchAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  const fetchKeywordsByGroup = async () => {
    const query = `
      query {
        keywordsByGroup {
          id
          keyword
        }
      }
    `;
    setLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const keywords = result.data.data.keywordsByGroup
      const sortedKeywords = [...keywords]
      sortedKeywords.sort((a, b) => a.id - b.id); // 追加順にソートしておく
      setGroupKeywords(sortedKeywords);
      handleSearchChange(searchKeyword);
      setSnackbarState({ open: true, message: 'キーワードを取得しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to fetch user group keywords:', error);
      setSnackbarState({ open: true, message: 'キーワードの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const fetchNewsCounts = async () => {
    const query = `
      query {
        newsCountByKeyword {
          keywordId
          newsCount
        }
      }
    `;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });

      const counts = result.data.data.newsCountByKeyword;
      const countsMap = counts.reduce((acc: Record<number, number>, item: { keywordId: number, newsCount: number }) => {
        acc[item.keywordId] = item.newsCount;
        return acc;
      }, {});
      setNewsCounts(countsMap); // Save news counts for each keyword
    } catch (error) {
      console.error('Failed to fetch news counts by keyword:', error);
      setSnackbarState({ open: true, message: 'ニュース数の取得に失敗しました', color: 'red', success: false });
    }
  };

  const addNewKeyword = async () => {
    if (!newKeyword) return;
    setAddingKeyword(true);

    const mutation = `
      mutation {
        addKeywordToGroup(keyword: "${newKeyword}") {
          id
          keyword
        }
      }
    `;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const addedKeyword = result.data.data.addKeywordToGroup;
      setGroupKeywords((prev) => [...prev, addedKeyword]);
      // 新しく追加されたキーワードのIDを登録（一定時間後に削除）
      setNewlyAddedKeywordIds((prev) => {
        const newSet = new Set(prev);
        newSet.add(addedKeyword.id);
        setTimeout(() => {
          setNewlyAddedKeywordIds((current) => {
            const updatedSet = new Set(current);
            updatedSet.delete(addedKeyword.id);
            return updatedSet;
          });
        }, 2000); // 2秒後に削除
        return newSet;
      });
      setNewKeyword('');
      setSnackbarState({ open: true, message: 'キーワードを追加しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to add keyword:', error);
      setSnackbarState({ open: true, message: 'キーワードの追加に失敗しました', color: 'red', success: false });
    } finally {
      setAddingKeyword(false);
    }
  };

  const removeKeywordsFromGroup = async () => {
    setLoading(true);
    setConfirmOpen(false)
    try {
      await Promise.all(
        selectedKeywords.map(async (id) => {
          const mutation = `
            mutation {
              removeKeywordFromGroup(keywordId: ${id})
            }
          `;
          await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
            headers: {
              "Authorization": `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }
          });
        })
      );
      setGroupKeywords((prev) => prev.filter((keyword) => !selectedKeywords.includes(keyword.id)));
      setSnackbarState({ open: true, message: '選択したキーワードを削除しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to remove keywords:', error);
      setSnackbarState({ open: true, message: '一括削除に失敗しました', color: 'blue', success: true });
    }
    setLoading(false);
    setConfirmOpen(false);
    setSelectedKeywords([]);
  };

  const fetchNewsByKeywordId = async (keywordId: number, keywordName: string) => {
    const query = `
      query {
        newsByKeywordId(keywordId: ${keywordId}) {
          id
          title
          url
          summary
          date
          keywords
          websiteTitle
        }
      }
    `;

    try {
      setLoading(true);
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setSelectedNews(result.data.data.newsByKeywordId);
      setSelectedKeyword(keywordName);
      setNewsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch news by keyword:', error);
      setSnackbarState({ open: true, message: 'ニュースの取得に失敗しました', color: 'red', success: false });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxToggle = (id: number) => {
    setSelectedKeywords((prev) =>
      prev.includes(id) ? prev.filter((keywordId) => keywordId !== id) : [...prev, id]
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const handleNewsItemsModalClose = () => {
    setNewsModalOpen(false);
  };

  useEffect(() => {
    if (accessToken) {
      fetchKeywordsByGroup();
      fetchNewsCounts();
    }
  }, [accessToken]);

  return (
    // 全体を格納するコンテナ
    <Box
      sx={{
        padding: 5,
        backgroundColor: '#FFF',
        borderRadius: 'var(--1, 8px)',
        flexGrow: 1,
        minWidth: '100%',
        boxSizing: 'border-box'
      }}>
      {/* ユーザー管理画面の本体 */}
      {/* ページタイトル */}
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%', // 33.6px
          }}
        >
          キーワード管理
        </Typography>
      </Box>

      {/* キーワード入力コンテナ */}
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: '#27272C',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            mb: 2,
          }}
        >
          登録したいキーワードを入力
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
          <TextField
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            placeholder="例)生成AI"
            variant="standard" // アンダーラインスタイルを標準とする
            InputProps={{
              disableUnderline: true, // MUIのデフォルトのアンダーラインを無効化
              sx: {
                height: '46px', // 全体の高さ
                padding: '0 12px', // 横方向の内側余白
                background: newKeyword.trim()
                  ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #EEEEF0' // 入力がある時の背景色
                  : '#EEEEF0', // 空の時の背景色
                borderRadius: 'var(--borderRadius, 4px) var(--borderRadius, 4px) var(--none, 0px) var(--none, 0px)', // 角丸設定
                alignItems: 'center', // 高さ方向で中央揃え
                borderBottom: '1px solid #9797A3', // アンダーラインを追加
              },
            }}
            sx={{
              width: '368px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  display: 'none', // デフォルトの枠線を非表示
                },
              },
              '& .MuiInputLabel-root': {
                display: 'none', // label を非表示
              },
            }}
          />

          <Button
            variant="contained"
            onClick={addNewKeyword}
            disabled={addingKeyword || !newKeyword}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 'var(--1, 8px) 22px',
              borderRadius: 'var(--borderRadius, 4px)',
              background: '#F1D04C',
              color: '#17171A',
              fontSize: '15px',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.24)',

              // 高さを padding から動的に計算
              height: `calc(var(--1, 8px) * 2 + 1em)`, // 1em はフォントサイズを基準
              lineHeight: 1.4, // 行間
              '&:hover': {
                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), var(--, #F1D04C)',
              },
              '&:focus': {
                background: 'linear-gradient(0deg, rgba(23, 23, 26, 0.07) 0%, rgba(23, 23, 26, 0.07) 100%), var(--, #F1D04C)'
              },
              '&:disabled': {
                background: '#EEEEF0', // 無効時の色（任意）
                color: '#B9B9C1',
              },
            }}
          >
            登録
          </Button>

        </Box>
      </Box>

      {/* 登録済みキーワードの管理コンテナ */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignContent: 'flex-start' }}>
        {/* フィルタやソートの設定部分 */}
        {/* 説明文言 */}
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
          }}
        >
          登録したキーワード
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* フィルタキーワード入力 */}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {/* 検索フィールド */}
            <TextField
              placeholder="キーワードを入力"
              value={searchKeyword}
              onChange={(e) => handleSearchChange(e.target.value)}
              sx={{
                width: '368px',
                '& .MuiInputBase-root': {
                  height: '40px', // 全体の高さを明示
                  padding: '0 8px', // 上下の隙間を調整
                  fontSize: '14px',
                },
                '& .MuiInputBase-input': {
                  height: '40px', // テキスト入力部分の高さ
                  lineHeight: '24px', // テキストの行間（フォントサイズ 14px + 上下 8px）
                  padding: '8px 0', // 上下の余白を均等に
                },
                '& .MuiInputLabel-root': {
                  display: 'none', // label を非表示
                },
              }}
              InputProps={{
                disableUnderline: true, // デフォルトの下線を無効化
                endAdornment: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%', // フィールドの高さに揃える
                      marginLeft: '8px', // アイコンとの余白を設定
                    }}
                  >
                    <SearchIcon />
                  </Box>
                ),
              }}
            />

            {/* ソート順ボタン */}
            <SortOrderButton sortOrder={sortOrder} setSortOrder={setSortOrder} />
          </Box>
          {/* 選択モード関連のボタンコンテナ */}
          <Box>
            {/* 選択モードの時表示されるボタン */}
            {selectMode && (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <Button
                  onClick={toggleSelectMode}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#DCDCE0',
                    borderRadius: '4px',
                    padding: '4px 16px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#46464F',
                    }}
                  >
                    選択終了
                  </Typography>
                </Button>
                <Button
                  onClick={unselectKeywords}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFF',
                    borderRadius: '4px',
                    border: '1px solid #CBCBD0',
                    padding: '4px 16px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#656572',
                    }}
                  >
                    選択解除
                  </Typography>
                </Button>
                <Button
                  onClick={() => setConfirmOpen(true)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFF',
                    borderRadius: '4px',
                    border: '1px solid #DB9396',
                    padding: '4px 16px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#B7272E',
                    }}
                  >
                    一括削除
                  </Typography>
                </Button>
              </Box>
            )}
            {/* 選択モードにするボタン */}
            {!selectMode && (
              <Button
                onClick={toggleSelectMode}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#DCDCE0',
                  borderRadius: '4px',
                  padding: '4px 16px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: '#46464F',
                  }}
                >
                  選択する
                </Typography>
              </Button>
            )}
          </Box>
        </Box>


        {/* キーワード一覧 */}
        <KeywordChipList
          keywords={groupKeywords}
          newlyAddedKeywordIds={newlyAddedKeywordIds}
          selectMode={selectMode}
          selectedKeywords={selectedKeywords}
          newsCounts={newsCounts}
          onCheckboxToggle={handleCheckboxToggle}
          onKeywordClick={fetchNewsByKeywordId} // Pass fetch function to handle clicks
        />
      </Box>

      {/* 以下はダイアログなど */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>選択したキーワードを削除しますか？
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} sx={{ color: '#27272C' }}>キャンセル</Button>
          <Button onClick={removeKeywordsFromGroup} sx={{ color: '#B7272E' }}>削除する</Button>
        </DialogActions>
      </Dialog>

      <NewsItemsModal
        open={newsModalOpen}
        onClose={handleNewsItemsModalClose}
        newsItems={selectedNews}
        modalTitle={selectedKeyword}
      />

      {/* Loading Spinner */}
      <Backdrop open={loading || addingKeyword} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />

    </Box>
  );
};

export default KeywordsPanel;
