import React, { useEffect, useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Backdrop,
  // MenuItem,
  // Select,
  // FormControl,
  // InputLabel,
  Snackbar
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { UserType, SnackbarType } from '../types/types';
import NotificationSnackbar from './shared/NotificationSnackbar';

const UsersPanel: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const [users, setUsers] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(false);

  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setNewUserLastName] = useState('');
  const [newUserRole, setNewUserRole] = useState<UserType['role']>('viewer');
  const [addingUser, setAddingUser] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        } catch (error) {
          console.error('Error fetching access token:', error);
          setSnackbarState({ open: true, message: 'トークンの取得に失敗しました', color: 'red', success: false });
        }
      }
    };
    fetchAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  const fetchUsersByGroup = async () => {
    const query = `
      query {
        usersByGroup {
          id
          email
          firstName
          lastName
          role
        }
      }
    `;

    setLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUsers(result.data.data.usersByGroup);
    } catch (error) {
      console.error('Failed to fetch users by group:', error);
      setSnackbarState({ open: true, message: 'ユーザーの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const validateInputs = () => {
    if (!newUserEmail) {
      setSnackbarState({ open: true, message: 'メールアドレスを入力してください', color: 'red', success: false });
      return false;
    }
    return true;
  };

  const addNewUser = async () => {
    if (!validateInputs()) return;

    const confirmAdd = window.confirm(`次のユーザーを追加しますか？\n\n名前: ${newUserFirstName} ${newUserLastName}\nメール: ${newUserEmail}\n権限: ${newUserRole}`);
    if (!confirmAdd) return;

    setAddingUser(true);

    const mutation = `
      mutation {
        addUser(email: "${newUserEmail}", firstName: "${newUserFirstName}", lastName: "${newUserLastName}", role: "${newUserRole}") {
          id
          email
          firstName
          lastName
          role
        }
      }
    `;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUsers((prevUsers) => [...prevUsers, result.data.data.addUser]);
      setSnackbarState({ open: true, message: 'ユーザーが追加されました', color: 'blue', success: true });
      setNewUserEmail('');
      setNewUserFirstName('');
      setNewUserLastName('');
    } catch (error) {
      console.error('Failed to add user:', error);
      setSnackbarState({ open: true, message: 'ユーザーの追加に失敗しました', color: 'red', success: false });
    }
    setAddingUser(false);
  };

  const handleRoleChange = async (userId: number, newRole: UserType['role']) => {
    const mutation = `
      mutation {
        updateUserRole(userId: ${userId}, role: "${newRole}") {
          id
          role
        }
      }
    `;

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? { ...user, role: newRole } : user))
      );
      setSnackbarState({ open: true, message: '権限を更新しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to update user role:', error);
      setSnackbarState({ open: true, message: '権限の更新に失敗しました', color: 'red', success: false });
    }
  };

  const deleteUser = async (userId: number) => {
    const mutation = `
      mutation {
        deleteUser(userId: ${userId})
      }
    `;

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      setSnackbarState({ open: true, message: 'ユーザーが削除されました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to delete user:', error);
      setSnackbarState({ open: true, message: 'ユーザーの削除に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    if (accessToken) {
      fetchUsersByGroup();
    }
  }, [accessToken]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6">ユーザー管理</Typography>

      <Box>
        <Typography variant="subtitle1">新しいユーザーを追加</Typography>
        <TextField
          label="メールアドレス"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="名前 (First Name)"
            value={newUserFirstName}
            onChange={(e) => setNewUserFirstName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="姓 (Last Name)"
            value={newUserLastName}
            onChange={(e) => setNewUserLastName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Typography variant="subtitle1">権限を選択</Typography>
        <RadioGroup
          row
          value={newUserRole}
          onChange={(e) => setNewUserRole(e.target.value as UserType['role'])}
        >
          <FormControlLabel value="editor" control={<Radio />} label="Editor" />
          <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
        </RadioGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewUser}
          disabled={addingUser}
        >
          {addingUser ? '追加中...' : 'ユーザーを追加'}
        </Button>
      </Box>

      <List>
        {users.map((user) => (
          <ListItem key={user.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ListItemText
              primary={`${user.email} (${user.firstName} ${user.lastName})`}
              secondary={`権限: ${user.role}`}
              sx={{ fontSize: '12px' }}
            />
            {/* <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel>権限を変更</InputLabel>
              <Select
                value={user.role}
                onChange={(e) => handleRoleChange(user.id, e.target.value as UserType['role'])}
                label="Role"
              >
                <MenuItem value="editor">Editor</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
              </Select>
            </FormControl> */}
            <IconButton edge="end" aria-label="delete" onClick={() => deleteUser(user.id)} disabled={loading}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <Backdrop open={loading || addingUser} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />
    </Box>
  );
};

export default UsersPanel;
