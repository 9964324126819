import React from 'react';
import { Box } from '@mui/material';

const Logo: React.FC = () => (
  <Box sx={{ color: '#FFF', fontFamily: 'Oleo Script', fontSize: '32px', fontWeight: 700 }}>
    News Bee
  </Box>
);

export default Logo;
