import React, { useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from "react-router-dom";
import {
  Button, Box, Snackbar, CircularProgress, Backdrop, AppBar, Toolbar, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Typography
} from '@mui/material';
import { ManageSearch, Language, ContactMail, AdminPanelSettings } from '@mui/icons-material';
import { ReactComponent as LogoutIcon } from './assets/icons/LogoutIcon.svg';
import { ReactComponent as ManageSearchIcon } from './assets/icons/ManageSearchIcon.svg';
import { ReactComponent as LanguageIcon } from './assets/icons/LanguageIcon.svg';
import { ReactComponent as ContactMailIcon } from './assets/icons/GroupsIcon.svg';
import KeywordsPanel from './components/KeywordsPanel';
import WebsitesPanel from './components/WebsitesPanel';
import UsersPanel from './components/UsersPanel';
import Logo from './components/Logo';
import { SnackbarType } from './types/types';
import './colors.css'

function App() {
  const { isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently, logout, error } = useAuth0();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [selectedPanel, setSelectedPanel] = useState<string>('keywords');
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });
  const orgName = user && user['https://newsbee.biz/org_name'];

  useEffect(() => {
    if (error) {
      console.error('Authentication error', error);
      setSnackbarState({ open: true, message: '認証に失敗しました。もう一度試してください。', color: 'red', success: false });
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const params = new URLSearchParams(location.search);
      const invitation = params.get("invitation");
      const organization = params.get("organization");

      if (invitation && organization) {
        // If invitation parameters are present, initiate login with them
        loginWithRedirect({
          authorizationParams: { invitation, organization }
        });
      } else {
        // Otherwise, proceed with the regular login flow
        loginWithRedirect();
      }
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        console.log(user)
        try {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
          setSnackbarState({ open: true, message: '認証に成功しました！', color: 'blue', success: true });
        } catch (error) {
          console.error('Error fetching access token:', error);
          setSnackbarState({ open: true, message: 'アクセストークンの取得に失敗しました。', color: 'red', success: false });
        }
      }
    };
    fetchAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleCloseSnackbar = () => {
    setSnackbarState((prev: SnackbarType) => ({ ...prev, open: false }));
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    window.location.reload();
  };

  // ボタンの外側のスタイル
  const getOuterButtonStyles = (isSelected: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 'var(--font-size-075-rem, 12px)',
    borderRadius: 'var(--borderRadius, 4px)',
    background: isSelected ? '#484850' : 'transparent',
    transition: 'background 0.3s ease',
  });

  // ボタンのアイコン部分のスタイル
  const innerButtonStyles = {
    width: 'var(--4, 32px)',
    height: 'var(--4, 32px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    color: '#fff',
  };

  // default, hover, focusedの状態ごとのボタンの背景色を返す
  const getButtonStylesByState = (state: 'default' | 'hover' | 'focused', baseColor: string) => {
    const shadow = '0px 2px 0px 0px rgba(0, 0, 0, 0.06)';

    switch (state) {
      case 'hover':
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case 'focused':
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case 'default':
      default:
        return {
          background: baseColor,
          boxShadow: shadow,
        };
    }
  };


  const renderSelectedPanel = () => {
    switch (selectedPanel) {
      case 'keywords':
        return <KeywordsPanel />;
      case 'websites':
        return <WebsitesPanel />;
      case 'users':
        return <UsersPanel />;
      default:
        return <KeywordsPanel />;
    }
  };

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isAuthenticated) {
    return (
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        {/* トップバー */}
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            backgroundColor: '#000',
            color: '#fff',
            minHeight: '60px',
            height: '60px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 40px 8px 24px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
            background: 'var(--BK-10000, #17171A)',
          }}
        >
          {/* News Beeのロゴ */}
          <Logo />
          <Box sx={{ padding: 0, marginLeft: 'auto' }}>
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: '"Open Sans"',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '140%',
                letterSpacing: '0.32px',
              }}
            >
              {orgName}
            </Typography>
          </Box>
        </AppBar>

        <Box sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh'
        }}>
          {/* サイドバー */}
          <Drawer
            variant="permanent"
            sx={{
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: 80,
                height: 'calc(100vh - 60px)', // トップバーを除いた高さ
                boxSizing: 'border-box',
                background: 'var(--BK-10000, #17171A)',
                color: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: 'var(--5, 40px) var(--font-size-075-rem, 12px)',
                marginTop: '60px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                flexGrow: 1,
                alignItems: 'center',
                padding: 0,
              }}
            >
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--4, 32px)',
                  alignItems: 'center',
                  padding: 0,
                  width: '100%',
                }}
              >
                {[
                  { panel: 'keywords', icon: <ManageSearchIcon />, baseColor: '#484850' },
                  { panel: 'websites', icon: <LanguageIcon />, baseColor: '#484850' },
                  { panel: 'users', icon: <ContactMailIcon />, baseColor: '#484850' },
                  { panel: 'admin', icon: <AdminPanelSettings />, baseColor: '#484850' },
                ].map(({ panel, icon, baseColor }) => (
                  <ListItem
                    key={panel}
                    sx={{
                      padding: 0,
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Button
                      onClick={() => setSelectedPanel(panel)}
                      sx={{
                        ...getOuterButtonStyles(selectedPanel === panel),
                        '&:hover': getButtonStylesByState('hover', baseColor),
                        '&:focus': getButtonStylesByState('focused', baseColor),
                        minWidth: '56px',
                      }}
                    >
                      <Box sx={innerButtonStyles}>{icon}</Box>
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Button
                onClick={() => handleLogout()}
                sx={{
                  ...getOuterButtonStyles(false),
                  '&:hover': getButtonStylesByState('hover', '#484850'),
                  '&:focus': getButtonStylesByState('focused', '#484850'),
                  minWidth: '56px',
                }}
              >
                <Box sx={innerButtonStyles}>
                  <LogoutIcon />
                </Box>
              </Button>
            </Box>
          </Drawer>

          {/* 本体コンテンツ */}
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: 'var(--2, 16px)',
              flex: 1, // 高さを全体に引き伸ばす
              overflow: 'auto', // スクロールを許可
              marginLeft: '80px', // サイドバーの幅分右に寄せる
              marginTop: '60px', // トップバーの高さ分下に寄せる
              backgroundColor: 'lightgray',
              width: '100%'
            }}
          >
            {renderSelectedPanel()}
          </Box>
        </Box>
      </Box>

    );
  }

  return null;
}

export default App;
