import React from 'react';
import { Snackbar, Box, Typography } from '@mui/material';
import { ReactComponent as SuccessIcon } from '../../assets/icons/CheckSuccessIcon.svg';
// import { ReactComponent as ErrorIcon } from '../../assets/icons/ErrorIcon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SnackbarType } from '../../types/types';

interface NotificationProps extends SnackbarType {
    onClose: () => void;
};

const NotificationSnackbar: React.FC<NotificationProps> = ({ open, onClose, message, success, color }) => {
    // プリセットカラー定義
    const presetColors = {
        // redの色は仮決め
        red: { background: '#FDECEA', textColor: '#5F2120', iconColor: '#C71625' },
        blue: { background: '#E5F6FD', textColor: '#014361', iconColor: '#0288D1' },
        green: { background: '#E1FED4', textColor: '#285414', iconColor: '#51D615' },
    };

    // 色の設定
    const { background, textColor, iconColor } =
        typeof color === 'string'
            ? presetColors[color as keyof typeof presetColors] || presetColors.blue // プリセットカラー
            : color || presetColors.blue; // カスタムカラーまたはデフォルト

    const Icon = success ? <SuccessIcon fill={iconColor} /> : <ErrorOutlineIcon color='error' />
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ContentProps={{
                sx: {
                    position: 'fixed',
                    top: '109px',
                    right: '48px',
                    width: '320px',
                    height: '48px',
                    background,
                    borderRadius: 'var(--borderRadius, 4px)',
                    display: 'flex',
                    padding: '6px var(--2, 16px)',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                },
            }}
            message={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: 0,
                        backgroundColor: 'transparent',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{Icon}</Box>
                    <Typography sx={{ color: textColor, fontSize: '14px', lineHeight: '150%' }}>{message}</Typography>
                </Box>
            }
        />
    );
};

export default NotificationSnackbar;
