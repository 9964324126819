import React, { useEffect, useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  Link,
  Checkbox,
  Chip
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, Switch } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { WebsiteType, SnackbarType, NewsType } from '../types/types';
import NotificationSnackbar from './shared/NotificationSnackbar';
import SortOrderButton, { SortOrder } from './shared/SortOrderButton';
import NewsItemsModal from './NewsItemsModal';
import { ReactComponent as InfoIconFilled } from '../assets/icons/InfoIconFilled.svg';

const WebsitesPanel: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const [groupWebsites, setGroupWebsites] = useState<WebsiteType[]>([]);
  const [unassociatedEnabledWebsites, setUnassociatedEnabledWebsites] = useState<WebsiteType[]>([]); // New state for enabled websites not in group
  const [newsCounts, setNewsCounts] = useState<Record<number, number>>({});
  const [loading, setLoading] = useState(false);
  const [newWebsiteUrl, setNewWebsiteUrl] = useState('');
  const [addingWebsite, setAddingWebsite] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });
  const [sortOrderForAdded, setSortOrderForAdded] = useState<SortOrder>('added')
  const [sortOrderForUnassociated, setSortOrderForUnassociated] = useState<SortOrder>('added')
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showUrls, setShowUrls] = useState<boolean>(false);
  const [isAddPanelOpen, setIsAddPanelOpen] = useState<boolean>(false);
  const [selectedWebsites, setSelectedWebsites] = useState<number[]>([]);

  const [selectedNews, setSelectedNews] = useState<NewsType[]>([]);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [selectedWebsiteTitle, setSelectedWebsiteTitle] = useState<string>('');

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
      }
    };
    fetchAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  // Fetch group-specific websites
  const fetchWebsitesByGroup = async () => {
    const query = `
      query {
        websitesByGroup {
          id
          name
          url
          title
          status
        }
      }
    `;

    setLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setGroupWebsites(result.data.data.websitesByGroup);
      setSnackbarState({ open: true, message: 'グループのWebサイトを取得しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to fetch group websites:', error);
      setSnackbarState({ open: true, message: 'グループのWebサイトの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const fetchNewsCounts = async () => {
    const query = `
      query {
        newsCountByWebsite {
          websiteId
          newsCount
        }
      }
    `;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const counts = result.data.data.newsCountByWebsite;
      const countsMap = counts.reduce((acc: Record<number, number>, curr: { websiteId: number; newsCount: number }) => {
        acc[curr.websiteId] = curr.newsCount;
        return acc;
      }, {});
      setNewsCounts(countsMap);
    } catch (error) {
      console.error('Failed to fetch news counts:', error);
    }
  };

  // Fetch enabled websites not in group
  const fetchUnassociatedEnabledWebsites = async () => {
    const query = `
      query {
        unassociatedEnabledWebsites {
          id
          name
          url
          title
          status
        }
      }
    `;

    setLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUnassociatedEnabledWebsites(result.data.data.unassociatedEnabledWebsites);
    } catch (error) {
      console.error('Failed to fetch unassociated enabled websites:', error);
      setSnackbarState({ open: true, message: '未登録のWebサイトの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const requestNewWebsite = async () => {
    if (!newWebsiteUrl) return;
    setAddingWebsite(true);
    setSnackbarState({ open: false, message: '', color: 'blue', success: true });

    const urlToAdd = newWebsiteUrl;

    const mutation = `
      mutation {
        requestWebsite(url: "${urlToAdd}") {
          id
          name
          url
          title
          status
        }
      }
    `;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const addedWebsite = result.data.data.requestWebsite;
      setGroupWebsites((prev) => [...prev, addedWebsite]);
      setNewWebsiteUrl('');
      setSnackbarState({ open: true, message: 'Webサイトの申請に成功しました。', color: 'green', success: true });
    } catch (error) {
      setSnackbarState({ open: true, message: 'Webサイトの申請に失敗しました。', color: 'red', success: false });
      console.error('Failed to request website:', error);
    } finally {
      setAddingWebsite(false);
    }
  };

  // Remove website from group
  const removeWebsiteFromGroup = async (websiteId: number) => {
    const mutation = `
      mutation {
        removeWebsiteFromGroup(websiteId:${websiteId})
      }
    `;

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      fetchWebsitesByGroup();
      fetchUnassociatedEnabledWebsites();
      setSnackbarState({ open: true, message: 'Webサイトを削除しました。', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to remove website:', error);
      setSnackbarState({ open: true, message: 'Webサイトの削除に失敗しました。', color: 'red', success: false });
    }
    setLoading(false);
  };

  const addWebsitesToGroup = async (websiteIds: number[]) => {
    const mutation = `
      mutation {
        addWebsitesToGroup(websiteIds: [${websiteIds.join(',')}])
      }
    `;

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setSnackbarState({ open: true, message: 'Webサイトをグループに追加しました。', color: 'blue', success: true });
      fetchWebsitesByGroup();
      fetchUnassociatedEnabledWebsites();
    } catch (error) {
      console.error('Failed to add website to group:', error);
      setSnackbarState({ open: true, message: 'Webサイトの追加に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const fetchNewsByWebsiteId = async (websiteId: number, websiteTitle: string) => {
    const query = `
      query {
        newsByWebsiteId(websiteId: ${websiteId}) {
          id
          title
          url
          summary
          date
          keywords
          websiteTitle
        }
      }
    `;

    try {
      setLoading(true);
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setSelectedNews(result.data.data.newsByWebsiteId);
      setSelectedWebsiteTitle(websiteTitle);
      setNewsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch news by website ID:', error);
      setSnackbarState({ open: true, message: 'ニュースの取得に失敗しました', color: 'red', success: false });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const handleNewsItemsModalClose = () => {
    setNewsModalOpen(false);
  };

  useEffect(() => {
    if (accessToken) {
      fetchWebsitesByGroup();
      fetchUnassociatedEnabledWebsites();
      fetchNewsCounts();
    }
  }, [accessToken]);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#F1D04C',
        '& + .MuiSwitch-track': {
          backgroundColor: '#F1D04C',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: '20px',
      height: '20px',
      filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
      '&.Mui-checked': {
        backgroundColor: '#F1D04C', // オン時のKnobの色
      },
    },
    '& .MuiSwitch-track': {
      width: '34px',
      height: '14px',
      borderRadius: '100px',
      backgroundColor: '#CBCBD0',
      display: 'flex',
      alignItems: 'center', // KnobとTrackの中央を揃える
    },
  }));

  const handleWebsiteSelection = (websiteId: number) => {
    setSelectedWebsites((prev) =>
      prev.includes(websiteId)
        ? prev.filter((id) => id !== websiteId) // 既に選択済みなら削除
        : [...prev, websiteId] // 未選択なら追加
    );
  }


  const sortedWebsites = [...groupWebsites].sort((a, b) => {
    if (sortOrderForAdded === 'count') {
      return (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0);
    } else if (sortOrderForAdded === 'title') {
      return a.title.localeCompare(b.title);
    } else {
      return a.id - b.id; // added順
    }
  });

  const displayedWebsites = sortedWebsites.filter(
    (website) =>
      website.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      website.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
      website.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // 新規ウェブサイト追加パネル上での検索
  const filteredUnassociatedWebsites = unassociatedEnabledWebsites.filter(
    (website) =>
      website.name.toLowerCase().includes(newWebsiteUrl.toLowerCase()) ||
      website.url.toLowerCase().includes(newWebsiteUrl.toLowerCase()) ||
      website.title.toLowerCase().includes(newWebsiteUrl.toLowerCase())
  );

  const sortedUnassociatedWebsites = [...filteredUnassociatedWebsites].sort((a, b) => {
    if (sortOrderForUnassociated === 'count') {
      return (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0);
    } else if (sortOrderForUnassociated === 'title') {
      return a.title.localeCompare(b.title);
    } else {
      return a.id - b.id; // added順
    }
  });



  return (
    <Box
      sx={{
        padding: 5,
        backgroundColor: '#FFF',
        borderRadius: 'var(--1, 8px)',
        flexGrow: 1,
        minWidth: '100%',
        boxSizing: 'border-box',
      }}>
      {/* Webサイト管理画面の本体 */}
      <Box mb={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 4 }}>
        {/* タイトル含め最上部のコンテナ */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography sx={{
            color: '#17171A',
            fontSize: '24px',
            fontWeight: 700,
            flex: '1 0 0'
          }}>Webサイト管理</Typography>
          <Button
            // onClick={requestNewWebsite}
            onClick={() => setIsAddPanelOpen(true)}
            disabled={addingWebsite}
            sx={{
              padding: '8px 16px',
              borderRadius: '4px',
              background: '#DCDCE0',
              boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
              gap: '4px',
              color: '#46464F',
              fontSize: '13px'
            }}
          >
            {addingWebsite ? '追加中...' : '+ 新規追加'}
          </Button>
          {/* 新規追加パネル */}
          {isAddPanelOpen && (
            <Box
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
                right: '16px',
                top: '76px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '400px',
                height: 'calc(100vh - 60px)',
                background: 'lightgray',
                boxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.10)',
                padding: '0px',
                boxSizing: 'border-box',
                gap: 0
              }}
            >
              {/* 本体 */}
              <Box sx={{
                backgroundColor: '#FFF',
                margin: 0,
                gap: 2,
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                flex: '1 1 auto',
                overflowY: 'auto',
                padding: '24px'
              }}>
                {/* ヘッダー部分 */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h6" sx={{ mb: 0 }}>
                    登録したいWebサイトを入力
                  </Typography>
                  <Button
                    onClick={() => setIsAddPanelOpen(false)}
                    sx={{
                      minWidth: '32px',
                      minHeight: '32px',
                      padding: 0,
                      background: 'transparent',
                      color: '#777',
                      fontSize: '16px',
                      lineHeight: '1',
                      borderRadius: '50%',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    X
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: 2 }}>
                  {/* URL入力フィールド */}
                  <TextField
                    value={newWebsiteUrl}
                    onChange={(e) => setNewWebsiteUrl(e.target.value)}
                    placeholder="キーワードもしくはURLを入力"
                    variant="outlined"
                    fullWidth
                    sx={{}}
                  />
                  <Button
                    onClick={requestNewWebsite}
                    variant="contained"
                    sx={{ backgroundColor: '#0288D1', color: '#fff' }}
                  >
                    申請
                  </Button>
                </Box>

                {/* 未登録のウェブサイト表示 */}
                <Box sx={{ width: '100%' }}>
                  {/* 並び順変更ボタン */}
                  <SortOrderButton sortOrder={sortOrderForUnassociated} setSortOrder={setSortOrderForUnassociated} />
                  <List
                    sx={{
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0px',
                      flex: '1 0 0',
                      alignSelf: 'stretch',
                      boxSizing: 'border-box',
                    }}
                  >
                    {sortedUnassociatedWebsites.map((website) => (
                      <ListItem
                        key={website.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: 0,
                          gap: 0,
                          margin: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '4px',
                            width: '100%'
                          }}
                        >
                          {/* チェックボックス */}
                          <Checkbox
                            checked={selectedWebsites.includes(website.id)}
                            onChange={() => handleWebsiteSelection(website.id)}
                          />
                          {/* ロゴ画像 */}
                          <Box
                            component="img"
                            src={`https://www.google.com/s2/favicons?domain=${website.url}`}
                            alt={`${website.title} logo`}
                            sx={{
                              width: '22px',
                              height: '22px',
                              borderRadius: '24px',
                              objectFit: 'contain',
                            }}
                          />
                          {/* Webサイト情報 */}
                          <ListItemText
                            sx={{
                              fontSize: '12px',
                              margin: 0,
                            }}
                            primary={
                              <Box>
                                <Link
                                  href={website.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{ color: '#0288D1', fontSize: '14px', fontWeight: 600 }}
                                >
                                  {website.title}
                                </Link>
                                <Button
                                  onClick={() => fetchNewsByWebsiteId(website.id, website.title)}
                                >
                                  <Typography>
                                    {` (${newsCounts[website.id] || 0})`}
                                  </Typography>
                                </Button>
                                {showUrls && (
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      color: '#666',
                                    }}
                                  >
                                    {website.url}
                                  </Typography>
                                )}
                              </Box>
                            }
                          />

                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
              {/* フッター */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 1,
                gap: '8px',
                flexShrink: 0,
                backgroundColor: '#EEEEF0'
              }}>
                <Button
                  variant="outlined"
                  onClick={() => setSelectedWebsites([])}
                  sx={{ background: '#EEEEF0', fontSize: '14px', borderColor: 'transparent', color: '#27272C' }}
                >
                  選択解除
                </Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    if (selectedWebsites.length === 0) return;
                    try {
                      await addWebsitesToGroup(selectedWebsites);
                      setSelectedWebsites([]); // 選択をクリア
                      console.log('登録が完了しました');
                    } catch (error) {
                      console.error('登録に失敗しました', error);
                    }
                  }}
                  sx={{ backgroundColor: '#F1D04C', color: '#17171A', fontSize: '14px', borderRadius: '4px' }}
                >
                  登録する
                </Button>
              </Box>
            </Box>
          )}


        </Box>
        {/* 最上部以外、本体のコンテナ */}
        <Box>
          {/* Input and Button for Requesting a New Website */}
          <Typography variant="h6" sx={{ mt: 0, color: '#27272C', fontSize: '16px' }}>登録したWebサイト</Typography>
          {/* 検索窓とオプション */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
            {/* 検索窓 */}
            <TextField
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="ウェブサイトを検索"
              variant="outlined"
              sx={{
                width: '300px',
                '& .MuiInputBase-root': {
                  height: '40px',
                },
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0 }}>
              {/* URLトグル */}
              <Typography mr='-4px' sx={{ color: '#7A7A7A', fontFamily: 'Roboto' }}>url</Typography>
              <CustomSwitch
                checked={showUrls}
                onChange={(e) => setShowUrls(e.target.checked)}
              />
            </Box>

            {/* 並び順ボタン */}
            <SortOrderButton sortOrder={sortOrderForAdded} setSortOrder={setSortOrderForAdded} />
          </Box>


          {/* List of Group Websites */}
          <List
            sx={{
              mt: 1,
              padding: '24px 0px',
              borderRadius: '4px',
              border: '1px solid #CBCBD0',
              display: 'flex',
              flexDirection: 'column',
              gap: '0px',
              flex: '1 0 0',
              alignSelf: 'stretch',
              boxSizing: 'border-box',
            }}
          >
            {displayedWebsites.map((website) => (
              <ListItem
                key={website.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '0px 24px',
                  gap: 0,
                  margin: 0,
                  '&:hover': {
                    backgroundColor: '#F5F5F5'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '4px',
                  }}
                >
                  {/* ロゴ画像 */}
                  <Box
                    component="img"
                    src={`https://www.google.com/s2/favicons?domain=${website.url}`}
                    alt={`${website.title} logo`}
                    sx={{
                      width: '22px',
                      height: '22px',
                      borderRadius: '24px',
                      objectFit: 'contain',
                    }}
                  />
                  {/* Webサイト情報 */}
                  <ListItemText
                    sx={{ fontSize: '12px', margin: 0 }}
                    primary={
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          {/* タイトル */}
                          <Link
                            href={website.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#0288D1', fontSize: '14px', fontWeight: 600 }}
                          >
                            {website.title}
                          </Link>

                          {/* ステータス */}
                          {website.status === 'pending' && (
                            <Chip
                              label={<Box sx={{ padding: '0px 6px' }}>申請中</Box>}
                              sx={{
                                backgroundColor: '#40CD00',
                                color: '#FFF',
                                fontSize: '12px',
                                height: '24px',
                                padding: '3px 4px',
                                borderRadius: '100px',
                                '& .MuiChip-label': {
                                  padding: 0,
                                },
                              }}
                            />
                          )}
                          {website.status === 'error' && (
                            <Chip
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      color: '#FFF',
                                      fontWeight: 400,
                                      padding: '0px 6px'
                                    }}
                                  >
                                    エラー
                                  </Typography>
                                  <NoMaxWidthTooltip
                                    title={
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start', // 左揃え
                                          justifyContent: 'center',
                                          padding: '4px 8px',
                                          borderRadius: '4px',
                                          color: '#FFF',
                                          fontSize: '10px',
                                          textAlign: 'left', // 左揃え
                                          whiteSpace: 'nowrap', // 改行しない
                                        }}
                                      >
                                        <Typography >
                                          なんらかの理由で申請が却下されました。
                                        </Typography>
                                        <Typography >
                                          urlが正しいか確認してください。
                                        </Typography>
                                        <Typography >
                                          ※解決しない場合は
                                          <Box
                                            component="span"
                                            sx={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            こちら
                                          </Box>
                                        </Typography>
                                      </Box>
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '16px',
                                        height: '16px',
                                        opacity: 0.7,
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <InfoIconFilled />
                                    </Box>
                                  </NoMaxWidthTooltip>

                                </Box>
                              }
                              sx={{
                                backgroundColor: '#D32F2F',
                                color: '#FFF',
                                fontSize: '12px',
                                height: '24px',
                                padding: '3px 4px',
                                borderRadius: '100px',
                                '& .MuiChip-label': {
                                  padding: 0,
                                },
                              }}
                            />
                          )}

                          {/* 件数ボタン */}
                          <Button onClick={() => fetchNewsByWebsiteId(website.id, website.title)}>
                            <Typography>{` (${newsCounts[website.id] || 0} 件)`}</Typography>
                          </Button>
                        </Box>
                        {/* URL表示 */}
                        {showUrls && (
                          <Typography
                            sx={{
                              fontSize: '10px',
                              color: '#C2C2C2',
                            }}
                          >
                            {website.url}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeWebsiteFromGroup(website.id)}
                  disabled={loading}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      <NewsItemsModal
        open={newsModalOpen}
        onClose={handleNewsItemsModalClose}
        newsItems={selectedNews}
        modalTitle={selectedWebsiteTitle}
      />

      {/* Loading Spinner */}
      <Backdrop open={loading || addingWebsite} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />
    </Box>
  );
};

export default WebsitesPanel;
